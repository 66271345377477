import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/de";
import "dayjs/locale/en";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const parsePhpToDayjsFormat = (phpFormat) => {
  let dayjsFormat = "";
  switch (phpFormat) {
    // date format
    case "d.m.Y":
      dayjsFormat = "DD.MM.YYYY";
      break;
    case "n/t/y":
      dayjsFormat = "M/D/YY";
      break;

    // time format
    case "H:i":
      dayjsFormat = "HH:mm";
      break;
    case "g.i a":
      dayjsFormat = "h.mm a";
      break;

    // date time format
    case "d.m.Y H:i":
      dayjsFormat = "DD.MM.YYYY HH:mm";
      break;
    case "d.m.Y g.i a":
      dayjsFormat = "DD.MM.YYYY h.mm a";
      break;
    case "n/t/y g.i a":
      dayjsFormat = "M/D/YY h.mm a";
      break;
    case "n/t/y H:i":
      dayjsFormat = "M/D/YY HH:mm";
      break;
  }

  return dayjsFormat;
};

const now = () => {
  return dayjs();
};

export default {
  dayjs,
  parsePhpToDayjsFormat,
  now,
};
